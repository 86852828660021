export const API_URL = process.env.REACT_APP_API_URL
export const POWERAPP_API_URL = process.env.REACT_APP_POWER_APP_API_URL

export enum ENV {
  PROD = 'production',
  STAGING = 'staging',
  DEV = 'development',
  PREPROD = 'preproduction',
}

export enum USER_RENDER_MODE {
  DEPARTMENTS = 'departments',
  DEFAULT = 'default',
}

/** permissions that are within display pages */
export type ActionPermissions = 'assign_tasks' | 'assign_labels' | 'assign_lg_tasks'

export type AppActionPermissions = {
  [perm in ActionPermissions]?: boolean
}

export enum REF_DATES {
  PS_INC_DATE = '2017-01-01',
}

export enum DISPLAY_PAGES {
  ITEM_VIEW_HANDWASHES = 'ITEM_VIEW_HANDWASHES',
  ITEM_VIEW_COMPLIANCE = 'ITEM_VIEW_COMPLIANCE',
  ITEM_UPLOAD_EMPLOYEES = 'ITEM_UPLOAD_EMPLOYEES',
  ITEM_VIEW_DEVICES = 'ITEM_VIEW_DEVICES',
  ITEM_VIEW_SCAN_IMAGES = 'ITEM_VIEW_SCAN_IMAGES',
  ITEM_SET_GOALS = 'ITEM_SET_GOALS',
  ITEM_VIEW_USERS = 'ITEM_VIEW_USERS',
  ITEM_CREATE_USERS = 'ITEM_CREATE_USERS',
  ITEM_EDIT_USERS = 'ITEM_EDIT_USERS',
  ITEM_VIEW_EMPLOYEES = 'ITEM_VIEW_EMPLOYEES',
  ITEM_EDIT_EMPLOYEES = 'ITEM_EDIT_EMPLOYEES',
  ITEM_VIEW_FORM_RESPONSES = 'ITEM_VIEW_FORM_RESPONSES',
  ITEM_VIEW_OVERVIEW = 'ITEM_VIEW_OVERVIEW',
  ITEM_DEVELOPER_OPTIONS = 'ITEM_DEVELOPER_OPTIONS',
  ITEM_VIEW_SENSORS = 'ITEM_VIEW_SENSORS',
  ITEM_EDIT_SENSORS = 'ITEM_EDIT_SENSORS',
  ITEM_APPROVE_COOLDOWN = 'ITEM_APPROVE_COOLDOWN',
  ITEM_VIEW_COOLDOWN = 'ITEM_VIEW_COOLDOWN',
  ITEM_GENERATE_REPORTS = 'ITEM_GENERATE_REPORTS',

  // labels
  ITEM_VIEW_LABELS = 'ITEM_VIEW_LABELS',
  ITEM_EDIT_LABELS = 'ITEM_EDIT_LABELS',
  ITEM_ORDER_LABELS = 'ITEM_ORDER_LABELS',
  ITEM_CREATE_LABELS = 'ITEM_CREATE_LABELS',

  // tasks
  ITEM_VIEW_TASKS = 'ITEM_VIEW_TASKS',
  ITEM_EDIT_TASKS = 'ITEM_EDIT_TASKS',
  ITEM_CREATE_TASKS = 'ITEM_CREATE_TASKS',

  // labels
  ITEM_VIEW_DIGITAL_PREP = 'ITEM_VIEW_DIGITAL_PREP',
  ITEM_EDIT_DIGITAL_PREP = 'ITEM_EDIT_DIGITAL_PREP',
  ITEM_CREATE_DIGITAL_PREP = 'ITEM_CREATE_DIGITAL_PREP',
}

/**
Routes and Paths
- Constant starts with "PATHNAME" => use it in routes.ts, _nav.ts (_nav.ts has the routes specifically tied to sidebar items); navigation about the app may be structured differently than the corresponding API routes/ endpoints
- Constant starts with "API_URL" => means its used solely for the purpose of hitting the restAPI, it should match the route/path used on the backend (@bp_route in chalice)
*/

//Authentication
export const API_URL_LOG_IN = `${API_URL}/auth/login`
export const API_URL_REFRESH_TOKEN = `${API_URL}/auth/refresh`
export const API_URL_REQUEST_PASSWORD_RESET = `${API_URL}/auth/forgot-password`
export const API_URL_SET_NEW_PASSWORD = `${API_URL}/auth/set-new-password`
export const API_URL_CHANGE_CURRENT_PASSWORD = `${API_URL}/auth/change-my-password`
export const API_URL_SET_NEW_PIN = `${API_URL}/auth/set-new-pin`

//API Access
export const API_URL_API_MANAGEMENT = `${API_URL}/api-management`

//Logging
export const API_URL_INTERACTION_LOGGING = `${API_URL}/logging`

//Locations
export const API_URL_MY_LOCATIONS_WITH_GOALS_SUMMARY = `${API_URL}/my-locations`
export const API_URL_MY_LOCATION_GROUPS_WITH_LOC_NAMES = `${API_URL}/my-location-groups`
export const API_URL_LOCATIONS_AND_LOCATION_GROUPS = `${API_URL}/goals-locations-and-location-groups`
export const API_URL_BULK_UPLOAD = `${API_URL}/bulk-upload`
export const API_URL_LOC_NAMES_FOR_UPLOAD_EMPLOYEES = `${API_URL}/locations-for-bulk-upload`

//Users
export const PATHNAME_USERS = `users`
export const API_URL_USERS = `${API_URL}/${PATHNAME_USERS}`

//-- Users Listing
export const PATHNAME_LIST_USERS = `${PATHNAME_USERS}`
export const API_URL_LIST_USERS = `${API_URL}/${PATHNAME_LIST_USERS}/list`

//-- Edit User
export const PATHNAME_EDIT_USER = `${PATHNAME_USERS}/edit`
export const API_URL_EDIT_USER = `${API_URL}/${PATHNAME_EDIT_USER}`

//-- Create User
export const PATHNAME_CREATE_USER = `${PATHNAME_USERS}/new`

//Goals
export const API_URL_COMPLIANCE_DATA = `${API_URL}/compliance`
export const API_URL_GOALS_DATA = `${API_URL}/goals`
export const API_URL_GOALS_SETTING = `${API_URL}/goals`
export const API_URL_LOCATIONS_GOAL_SUMMARY = `${API_URL}/goals-list`

//Filters
export const API_URL_GET_FILTERS_FOR_GOALS_DASHBOARD = `${API_URL}/filters?pageName=GOALS_DASHBOARD`
export const API_URL_GET_FILTERS_FOR_COMP_DASHBOARD = `${API_URL}/filters?pageName=COMPLIANCE_DASHBOARD`
export const API_URL_GET_FILTERS_FOR_DEVICES_DASHBOARD = `${API_URL}/filters?pageName=DEVICES_DASHBOARD`
export const API_URL_GET_FILTERS_FOR_SCAN_IMAGES_DASHBOARD = `${API_URL}/filters?pageName=SCAN_IMAGES_DASHBOARD`
export const API_URL_GET_FILTERS_FOR_HANDWASHES_DASHBOARD = `${API_URL}/filters?pageName=HANDWASHES_DASHBOARD`
export const API_URL_GET_FILTERS_FOR_WELLNESS_DASHBOARD = `${API_URL}/filters?pageName=FORM_DASHBOARD`
export const API_URL_GET_FILTERS_FOR_SENSOR_OVERVIEW = `${API_URL}/filters?pageName=SENSORS_OVERVIEW`
export const API_URL_GET_FILTERS_FOR_SENSORS_PROCESSES = `${API_URL}/filters?pageName=SENSORS_PROCESSES`
export const API_URL_GET_FILTERS_FOR_SENSORS_GRAPH = `${API_URL}/filters?pageName=SENSORS_GRAPH`
export const API_URL_GET_FILTERS_FOR_SENSORS_ALERTS = `${API_URL}/filters?pageName=SENSORS_ALERTS`

//Dashboards
export const API_URL_DEVICE_ACTIVITY = `${API_URL}/devices-activity`
export const API_URL_HANDWASHES_DASHBOARD = `${API_URL}/handwashes-dashboard`
export const API_URL_HW_DASHBOARD_SECTION = `${API_URL}/handwashes-dashboard/`
export const API_URL_HW_DASHBOARD_MULTI_LEVEL_CHARTS = `${API_URL_HANDWASHES_DASHBOARD}/multi-level-charts-section`
export const API_URL_HW_DASHBOARD_ACTIVITY_CHARTS = `${API_URL_HANDWASHES_DASHBOARD}/activity-charts-section`
export const API_URL_SCAN_IMAGES = `${API_URL}/scan-images`

//Employees
export const API_URL_ALL_EMPLOYEES = `${API_URL}/my-employees`

//Forms
export const API_URL_FORMS = `${API_URL}/forms`
export const API_URL_WELLNESS_CHECK_DASHBOARD = `${API_URL}/forms/overview`
export const API_URL_WELLNESS_CHECK_ANSWER_BREAKDOWN = `${API_URL}/forms/overview/answer_breakdown`
export const API_URL_WELLNESS_CHECK_EMPLOYEE_BREAKDOWN = `${API_URL}/forms/question/employee_breakdown`

//Sensors
export const API_URL_SENSORS = `${API_URL}/sensors`
export const SENSOR_DASHBOARD_PATHNAME = 'sensors-overview'
export const API_URL_SENSORS_GET_MODELS = `${API_URL_SENSORS}/models`
export const API_URL_SENSORS_GET_LIST = `${API_URL_SENSORS}/list`
export const API_URL_SENSORS_CRUD = `${API_URL_SENSORS}/crud`
export const API_URL_SENSORS_PERMISSIONS = `${API_URL_SENSORS}/permissions`
export const API_URL_SENSORS_USERS_BY_LOCATION = `${API_URL_SENSORS}/users`
export const API_URL_SENSORS_TEMPLATES = `${API_URL_SENSORS}/templates`
export const API_URL_SENSORS_ALERTS = `${API_URL}/sensor_alerts`
export const API_URL_SENSORS_PROCESSES_OVERVIEW = `${API_URL}/cooldown/overview`
export const API_URL_SENSORS_PROCESSES_CANCEL = `${API_URL}/cooldown/cancel`
export const API_URL_SENSORS_PROCESSES_APPROVE = `${API_URL}/cooldown/approve`
export const API_URL_SENSORS_PROCESSES_COMMENT = `${API_URL}/cooldown/comment`
export const API_URL_SENSORS_PROCESSES_START = `${API_URL}/cooldown/start`
export const API_URL_SENSORS_PROCESSES_EDIT = `${API_URL}/cooldown/edit`
export const API_URL_SENSORS_PROCESSES_READINGS = `${API_URL}/cooldown/sensor-readings`

export const API_URL_SENSORS_PROCESSES_LAST_READING = `${API_URL}/cooldown/last-reading`
export const API_URL_SENSORS_PROCESSES_FOOD_ITEMS = `${API_URL}/cooldown/food-items`
export const API_URL_SENSORS_PROCESSES_SENSOR_ITEMS = `${API_URL}/cooldown/sensors`
export const API_URL_SENSORS_PROCESSES_TYPES = `${API_URL}/cooldown/process-types`

export const SENSOR_DATA_REPORTING_PATHNAME = 'sensor-data-reporting'

//Tasks
export const API_URL_TASKS_GET_LIST = `${POWERAPP_API_URL}/tasks/list`
export const API_URL_TASK_GET = (taskId?: string) => {
  const params = new URLSearchParams()

  if (taskId) {
    params.append('taskId', taskId)
  }

  return `${POWERAPP_API_URL}/tasks/details?${params.toString()}`
}
export const API_URL_TASK_POST = () => `${POWERAPP_API_URL}/tasks/details`
export const API_URL_TASKS_TOGGLE_ACTIVE = `${POWERAPP_API_URL}/tasks/active`
export const API_URLF_TASKS_GET_CA = (customerId: number) => `${POWERAPP_API_URL}/tasks/corrective-action/list?customerId=${customerId}`
export const API_URL_TASKS_POST_CA = `${POWERAPP_API_URL}/tasks/corrective-action/details`
export const API_URL_TASKS_REPORTING_LOCATIONS = `${POWERAPP_API_URL}/locations/list`
export const API_URL_TASKS_REPORTING_ACTIVE_TASKLISTS_DATES = `${POWERAPP_API_URL}/tasks/active-task-list/dates`
export const API_URL_TASKS_REPORTING_ACTIVE_TASKLISTS = `${POWERAPP_API_URL}/tasks/active-task-list`

// Labels
export const API_URL_LABELS_CATEGORY_GET_ALL = `${POWERAPP_API_URL}/labels/category`
export const API_URL_LABELS_CATEGORY_SET_ACTIVE = `${POWERAPP_API_URL}/labels/category/active`
export const API_URLF_LABELS_CATEGORY_GET_DETAILS = (categoryId?: number) => {
  if (categoryId) {
    const params = new URLSearchParams()
    params.append('categoryId', categoryId.toString())
    return `${POWERAPP_API_URL}/labels/category/details?${params.toString()}`
  } else {
    // Returning a falsy value tells SWR to not start the request.
    return false
  }
}
export const API_URL_LABELS_CATEGORY_SET_DETAILS = `${POWERAPP_API_URL}/labels/category/details`
export const API_URL_LABELS_ITEM_GET_ALL = `${POWERAPP_API_URL}/labels/item`
export const API_URL_LABELS_ITEM_SET_ACTIVE = `${POWERAPP_API_URL}/labels/item/active`
export const API_URL_LABELS_ITEM_GET_OPTIONS = `${POWERAPP_API_URL}/labels/item/options`
export const API_URLF_LABELS_ITEM_GET_DETAILS = (itemId?: number) => {
  if (itemId) {
    const params = new URLSearchParams()
    params.append('itemId', itemId.toString())
    return `${POWERAPP_API_URL}/labels/item/details?${params.toString()}`
  } else {
    // Returning a falsy value tells SWR to not start the request.
    return false
  }
}
export const API_URL_LABELS_ITEM_SET_DETAILS = `${POWERAPP_API_URL}/labels/item/details`
export const API_URL_LABELS_ORDER_GET_OPTIONS = `${POWERAPP_API_URL}/labels/order/options`
export const API_URL_LABELS_ORDER_SET_DETAILS = `${POWERAPP_API_URL}/labels/order/details`

// Digital Prep
export const API_URL_DIGIPREP_ITEM_GET_ALL = `${POWERAPP_API_URL}/digital-prep/configured-items`
export const API_URL_DIGIPREP_ITEM_GET_OPTIONS = (itemId?: string) => {
  const url = `${POWERAPP_API_URL}/digital-prep/configured-items/options`
  if (itemId) {
    return `${url}?itemId=${itemId}`
  }

  return url
}
export const API_URL_DIGITAL_PREP_ITEM_GET_DETAILS = (itemId: number) => `${POWERAPP_API_URL}/digital-prep/configured-items/${itemId}`
export const API_URL_DIGITAL_PREP_ITEM_SET_DETAILS = `${POWERAPP_API_URL}/digital-prep/configured-items`
export const API_URL_DIGITAL_PREP_ITEM_GET_UPLOAD = `${POWERAPP_API_URL}/digital-prep/get-upload-url`
export const API_URL_DIGIPREP_CATEGORY_GET_ALL = `${POWERAPP_API_URL}/digital-prep/categories`
export const API_URL_DIGIPREP_CATEGORY_GET_OPTIONS = `${POWERAPP_API_URL}/digital-prep/categories/options`
export const API_URL_DIGITAL_PREP_CATEGORY_GET_DETAILS = (id: number) => `${POWERAPP_API_URL}/digital-prep/categories/${id}`
export const API_URL_DIGITAL_PREP_CATEGORY_SET_DETAILS = `${POWERAPP_API_URL}/digital-prep/categories`
export const API_URL_DIGIPREP_ITEM_TOGGLE_ACTIVE = `${POWERAPP_API_URL}/digital-prep/configured-items/active`
export const API_URL_DIGIPREP_CATEGORY_TOGGLE_ACTIVE = `${POWERAPP_API_URL}/digital-prep/categories/active`

// Reports
export const API_URL_REPORTS_TASKS_COMPLETION = `${POWERAPP_API_URL}/reports/report/task-hand-wash-checklist`
export const API_URL_REPORTS_TASKS_RESPONSE = `${POWERAPP_API_URL}/reports/report/task-individual`
export const API_URL_REPORTS_TASKS_EXCEL_OVERVIEW = `${POWERAPP_API_URL}/reports/report/xlsx-checklist-by-date`
export const API_URL_REPORTS_TASKS_EXCEL_RESPONSE = `${POWERAPP_API_URL}/reports/report/xlsx-task-details-by-location`
export const API_URL_REPORTS_TASKS_EXCEL_OVERALL = `${POWERAPP_API_URL}/reports/report/xlsx-task-details-overall`
export const API_URL_REPORTS_SENSORS_HACCP_A1 = `${POWERAPP_API_URL}/reports/report/haccp-a1`
export const API_URL_REPORTS_SENSORS_HACCP_A2 = `${POWERAPP_API_URL}/reports/report/haccp-a2`
export const API_URL_REPORTS_SENSORS_HACCP_A3 = `${POWERAPP_API_URL}/reports/report/haccp-a3`
