import { SelectableItem } from '../types/components-general.types'

export const previousListItem = (searchList: any[], value: any) => {
  let prevIndex = searchList.indexOf(value) - 1
  prevIndex = prevIndex < 0 ? searchList.length - 1 : prevIndex
  //console.log("Previous index is: ", prevIndex)
  return searchList[prevIndex]
}

export const linspace = (start: number, stop: number, step: number) => {
  const aLen = Math.ceil((stop - start) / step) + 1
  let idx = 0
  return Array.apply(null, Array(aLen)).map((val: any) => start + step * idx++)
}

export const buildSelectableArray = <T>(inputArray: Array<unknown>, labelKey: string, valueKey: string): Array<SelectableItem<T>> => {
  const selectableItems = inputArray
    .filter(
      (item): item is T & Record<string, unknown | string> =>
        typeof item === 'object' && item !== null && labelKey in item && valueKey in item
    )
    .map((item) => {
      const selectableItem: SelectableItem<T> = {
        label: String(item[labelKey]),
        value: item[valueKey],
        source: { ...item } as typeof item,
      }
      return selectableItem
    })
  return selectableItems.length ? [...selectableItems] : []
}
