import React, { useState } from 'react'
import CIcon from '@coreui/icons-react'

import { CCardHeader, CCard, CCollapse } from '@coreui/react'
import { dispatchResizeEvent } from '../../../events/eventDispatcher'
import PContainer from './PContainer'
import PTitle from '../text/PTitle'
import { useFela } from 'react-fela'
import { maxWidthClass } from '../../styles/containers'
import PElement from './PElement'

const PCollapseCard = (props: any) => {
  const { title } = props

  const [expanded, setExpanded] = useState(false)
  const { css } = useFela()
  const handleExpansion = () => {
    setExpanded(!expanded)
    dispatchResizeEvent(50)
  }
  return (
    <PContainer flexDirection="row" maxWidth="100%" justifyContent="space-between">
      <CCard className={css(maxWidthClass)}>
        <CCardHeader style={{ cursor: 'pointer' }} onClick={handleExpansion}>
          <PTitle isRow width="50%" fontSize="1.25rem" title={title} justifyContent="flex-start" />
          <PElement isRow width="50%" justifyContent="flex-end">
            <CIcon name={expanded ? 'cil-chevron-top' : 'cil-chevron-bottom'} />
          </PElement>
        </CCardHeader>
        <CCollapse show={expanded}>
          <PContainer flexDirection="column" width="100%" justifyContent="space-between">
            {props.children}
          </PContainer>
        </CCollapse>
      </CCard>
    </PContainer>
  )
}
export default PCollapseCard
