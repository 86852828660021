import moment, { Moment } from 'moment-timezone'
export const dateTimeFormat = 'D/M/YYYY hh:mm:ss'

export const defaultTimeFormat = 'hh:mm A'
export const defaultTimeFormatLowerCase = 'hh:mm a'
export const timeZoneFull = 'YYYY-MM-DDTHH:mm:ssZ'
export const defaultDateFormat = 'YYYY-MM-DD'
export const stringToMoment = (momentString: string | Time | null, timeFormat: string = defaultTimeFormat) =>
  moment(momentString, timeFormat)
export const momentToString = (timeMoment: Moment, mFormat = 'hh:mm A') => `${timeMoment.format(mFormat)}`
export const unixToMoment = (unixTime: any) => moment.unix(unixTime)
export const momentToUnix = (momentObject: Moment) => momentObject.unix()
export const unixToString = (unixTime: any, timeFormat: string = defaultTimeFormat) => moment.unix(unixTime).format(timeFormat)
export const stringToUnix = (momentString: string, timeFormat: string = defaultTimeFormat) =>
  momentToUnix(stringToMoment(momentString, timeFormat))

export const generateAllHourOptionsForDropdown = () => {
  const hours = ['12', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11']
  const minutes = ['00', '15', '30', '45']
  const amOrPm = ['AM', 'PM']

  const items: any[] = []

  amOrPm.forEach((ampm: any) => {
    hours.forEach((hr: any) => {
      minutes.forEach((min: any) => {
        const item = hr + ':' + min + ' ' + ampm
        items.push({
          label: item,
          value: item,
        })
      })
    })
  })

  return items
}

export const allTimeZonesUS = moment.tz.zonesForCountry('US').map((item: any) => {
  return { label: item, value: item }
}) // only US timezones
export const allTimeZones = moment.tz.names().map((item: any) => {
  return { label: item, value: item }
}) // all timezones

export const dayCheckboxItems = [
  {
    dayName: 'Monday',
    dayNameShort: 'Mon',
    order: 0,
  },
  {
    dayName: 'Tuesday',
    dayNameShort: 'Tue',
    order: 1,
  },
  {
    dayName: 'Wednesday',
    dayNameShort: 'Wed',
    order: 2,
  },
  {
    dayName: 'Thursday',
    dayNameShort: 'Thu',
    order: 3,
  },
  {
    dayName: 'Friday',
    dayNameShort: 'Fri',
    order: 4,
  },
  {
    dayName: 'Saturday',
    dayNameShort: 'Sat',
    order: 5,
  },
  {
    dayName: 'Sunday',
    dayNameShort: 'Sun',
    order: 6,
  },
]
export enum Time {
  '12:00 AM' = '12:00 AM',
  '12:15 AM' = '12:15 AM',
  '12:30 AM' = '12:30 AM',
  '12:45 AM' = '12:45 AM',
  '1:00 AM' = '1:00 AM',
  '1:15 AM' = '1:15 AM',
  '1:30 AM' = '1:30 AM',
  '1:45 AM' = '1:45 AM',
  '2:00 AM' = '2:00 AM',
  '2:15 AM' = '2:15 AM',
  '2:30 AM' = '2:30 AM',
  '2:45 AM' = '2:45 AM',
  '3:00 AM' = '3:00 AM',
  '3:15 AM' = '3:15 AM',
  '3:30 AM' = '3:30 AM',
  '3:45 AM' = '3:45 AM',
  '4:00 AM' = '4:00 AM',
  '4:15 AM' = '4:15 AM',
  '4:30 AM' = '4:30 AM',
  '4:45 AM' = '4:45 AM',
  '5:00 AM' = '5:00 AM',
  '5:15 AM' = '5:15 AM',
  '5:30 AM' = '5:30 AM',
  '5:45 AM' = '5:45 AM',
  '6:00 AM' = '6:00 AM',
  '6:15 AM' = '6:15 AM',
  '6:30 AM' = '6:30 AM',
  '6:45 AM' = '6:45 AM',
  '7:00 AM' = '7:00 AM',
  '7:15 AM' = '7:15 AM',
  '7:30 AM' = '7:30 AM',
  '7:45 AM' = '7:45 AM',
  '8:00 AM' = '8:00 AM',
  '8:15 AM' = '8:15 AM',
  '8:30 AM' = '8:30 AM',
  '8:45 AM' = '8:45 AM',
  '9:00 AM' = '9:00 AM',
  '9:15 AM' = '9:15 AM',
  '9:30 AM' = '9:30 AM',
  '9:45 AM' = '9:45 AM',
  '10:00 AM' = '10:00 AM',
  '10:15 AM' = '10:15 AM',
  '10:30 AM' = '10:30 AM',
  '10:45 AM' = '10:45 AM',
  '11:00 AM' = '11:00 AM',
  '11:15 AM' = '11:15 AM',
  '11:30 AM' = '11:30 AM',
  '11:45 AM' = '11:45 AM',
  '12:00 PM' = '12:00 PM',
  '12:15 PM' = '12:15 PM',
  '12:30 PM' = '12:30 PM',
  '12:45 PM' = '12:45 PM',
  '1:00 PM' = '1:00 PM',
  '1:15 PM' = '1:15 PM',
  '1:30 PM' = '1:30 PM',
  '1:45 PM' = '1:45 PM',
  '2:00 PM' = '2:00 PM',
  '2:15 PM' = '2:15 PM',
  '2:30 PM' = '2:30 PM',
  '2:45 PM' = '2:45 PM',
  '3:00 PM' = '3:00 PM',
  '3:15 PM' = '3:15 PM',
  '3:30 PM' = '3:30 PM',
  '3:45 PM' = '3:45 PM',
  '4:00 PM' = '4:00 PM',
  '4:15 PM' = '4:15 PM',
  '4:30 PM' = '4:30 PM',
  '4:45 PM' = '4:45 PM',
  '5:00 PM' = '5:00 PM',
  '5:15 PM' = '5:15 PM',
  '5:30 PM' = '5:30 PM',
  '5:45 PM' = '5:45 PM',
  '6:00 PM' = '6:00 PM',
  '6:15 PM' = '6:15 PM',
  '6:30 PM' = '6:30 PM',
  '6:45 PM' = '6:45 PM',
  '7:00 PM' = '7:00 PM',
  '7:15 PM' = '7:15 PM',
  '7:30 PM' = '7:30 PM',
  '7:45 PM' = '7:45 PM',
  '8:00 PM' = '8:00 PM',
  '8:15 PM' = '8:15 PM',
  '8:30 PM' = '8:30 PM',
  '8:45 PM' = '8:45 PM',
  '9:00 PM' = '9:00 PM',
  '9:15 PM' = '9:15 PM',
  '9:30 PM' = '9:30 PM',
  '9:45 PM' = '9:45 PM',
  '10:00 PM' = '10:00 PM',
  '10:15 PM' = '10:15 PM',
  '10:30 PM' = '10:30 PM',
  '10:45 PM' = '10:45 PM',
  '11:00 PM' = '11:00 PM',
  '11:15 PM' = '11:15 PM',
  '11:30 PM' = '11:30 PM',
  '11:45 PM' = '11:45 PM',
}

export const allFullHoursAndMinutesTimes: Time[] = [
  Time['12:00 AM'],
  Time['12:15 AM'],
  Time['12:30 AM'],
  Time['12:45 AM'],
  Time['1:00 AM'],
  Time['1:15 AM'],
  Time['1:30 AM'],
  Time['1:45 AM'],
  Time['2:00 AM'],
  Time['2:15 AM'],
  Time['2:30 AM'],
  Time['2:45 AM'],
  Time['3:00 AM'],
  Time['3:15 AM'],
  Time['3:30 AM'],
  Time['3:45 AM'],
  Time['4:00 AM'],
  Time['4:15 AM'],
  Time['4:30 AM'],
  Time['4:45 AM'],
  Time['5:00 AM'],
  Time['5:15 AM'],
  Time['5:30 AM'],
  Time['5:45 AM'],
  Time['6:00 AM'],
  Time['6:15 AM'],
  Time['6:30 AM'],
  Time['6:45 AM'],
  Time['7:00 AM'],
  Time['7:15 AM'],
  Time['7:30 AM'],
  Time['7:45 AM'],
  Time['8:00 AM'],
  Time['8:15 AM'],
  Time['8:30 AM'],
  Time['8:45 AM'],
  Time['9:00 AM'],
  Time['9:15 AM'],
  Time['9:30 AM'],
  Time['9:45 AM'],
  Time['10:00 AM'],
  Time['10:15 AM'],
  Time['10:30 AM'],
  Time['10:45 AM'],
  Time['11:00 AM'],
  Time['11:15 AM'],
  Time['11:30 AM'],
  Time['11:45 AM'],
  Time['12:00 PM'],
  Time['12:15 PM'],
  Time['12:30 PM'],
  Time['12:45 PM'],
  Time['1:00 PM'],
  Time['1:15 PM'],
  Time['1:30 PM'],
  Time['1:45 PM'],
  Time['2:00 PM'],
  Time['2:15 PM'],
  Time['2:30 PM'],
  Time['2:45 PM'],
  Time['3:00 PM'],
  Time['3:15 PM'],
  Time['3:30 PM'],
  Time['3:45 PM'],
  Time['4:00 PM'],
  Time['4:15 PM'],
  Time['4:30 PM'],
  Time['4:45 PM'],
  Time['5:00 PM'],
  Time['5:15 PM'],
  Time['5:30 PM'],
  Time['5:45 PM'],
  Time['6:00 PM'],
  Time['6:15 PM'],
  Time['6:30 PM'],
  Time['6:45 PM'],
  Time['7:00 PM'],
  Time['7:15 PM'],
  Time['7:30 PM'],
  Time['7:45 PM'],
  Time['8:00 PM'],
  Time['8:15 PM'],
  Time['8:30 PM'],
  Time['8:45 PM'],
  Time['9:00 PM'],
  Time['9:15 PM'],
  Time['9:30 PM'],
  Time['9:45 PM'],
  Time['10:00 PM'],
  Time['10:15 PM'],
  Time['10:30 PM'],
  Time['10:45 PM'],
  Time['11:00 PM'],
  Time['11:15 PM'],
  Time['11:30 PM'],
  Time['11:45 PM'],
]

export const wholeHoursAMPM = [
  '12 AM',
  '1 AM',
  '2 AM',
  '3 AM',
  '4 AM',
  '5 AM',
  '6 AM',
  '7 AM',
  '8 AM',
  '9 AM',
  '10 AM',
  '11 AM',
  '12 PM',
  '1 PM',
  '2 PM',
  '3 PM',
  '4 PM',
  '5 PM',
  '6 PM',
  '7 PM',
  '8 PM',
  '9 PM',
  '10 PM',
  '11 PM',
]
export const allHoursDropdown = [
  {
    label: '24h',
    value: -1,
  },
  {
    label: '12 AM',
    value: 0,
  },
  {
    label: '1 AM',
    value: 1,
  },
  {
    label: '2 AM',
    value: 2,
  },
  {
    label: '3 AM',
    value: 3,
  },
  {
    label: '4 AM',
    value: 4,
  },
  {
    label: '5 AM',
    value: 5,
  },
  {
    label: '6 AM',
    value: 6,
  },
  {
    label: '7 AM',
    value: 7,
  },
  {
    label: '8 AM',
    value: 8,
  },
  {
    label: '9 AM',
    value: 9,
  },
  {
    label: '10 AM',
    value: 10,
  },
  {
    label: '11 AM',
    value: 11,
  },
  {
    label: '12 PM',
    value: 12,
  },
  {
    label: '1 PM',
    value: 13,
  },
  {
    label: '2 PM',
    value: 14,
  },
  {
    label: '3 PM',
    value: 15,
  },
  {
    label: '4 PM',
    value: 16,
  },
  {
    label: '5 PM',
    value: 17,
  },
  {
    label: '6 PM',
    value: 18,
  },
  {
    label: '7 PM',
    value: 19,
  },
  {
    label: '8 PM',
    value: 20,
  },
  {
    label: '9 PM',
    value: 21,
  },
  {
    label: '10 PM',
    value: 22,
  },
  {
    label: '11 PM',
    value: 23,
  },
]
export const replaceBadInputs = (val: any) => {
  // Replace impossible inputs as they appear
  val = val.replace(/[^\dh:]/, '0')
  val = val.replace(/^[^0-2]/, '0')
  val = val.replace(/^([2-9])[4-9]/, '$1')
  val = val.replace(/^\d[:h]/, '0')
  val = val.replace(/^([01][0-9])[^:h]/, '$1')
  val = val.replace(/^(2[0-3])[^:h]/, '$1')
  val = val.replace(/^(\d{2}[:h])[^0-5]/, '$1')
  val = val.replace(/^(\d{2}h)./, '$1')
  val = val.replace(/^(\d{2}:[0-5])[^0-9]/, '$1')
  val = val.replace(/^(\d{2}:\d[0-9])./, '$1')
  return val
}
